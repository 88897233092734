import React from 'react';
import { Typography } from '@mui/material';
import TableResponsive from './../../components/TableResponsive';

const ApiDocServers = ({ servers, sx }) => {
  return (
    <Typography variant="paragraph" sx={ sx ? sx : {} }>
      <TableResponsive>
        <table>
          <thead>
            <tr>
              <td>Environment</td>
              <td>URL</td>
            </tr>
          </thead>
          <tbody>
            {servers.map((item, index) => (
              <tr key={index}>
                <td>{item.env}</td>
                <td><code>{item.url}</code></td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableResponsive>
    </Typography>
  );
};

export default ApiDocServers;
