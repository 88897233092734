import React from "react";
import { Typography } from "@mui/material";
import ApiDocPage from "./ApiDocPage";
import ApiDocEndpoint from "./ApiDocEndpoint";
import ApiDocServers from "./ApiDocServers";
import QuickTokenEndpoint from "./QuickTokenEndpoint";

const MetarTafSigmetApi = () => {
  return (
    <ApiDocPage title="METAR, TAF & SIGMET Data API">
      {/* Description */}
      <Typography variant="h2">Description</Typography>
      <Typography variant="paragraph">
        This API returns METARs, TAFs, and SIGMETs for airports and areas
        worldwide. Call by ICAO airport ID, specific latitude/longitude, or
        area/bounding box.
      </Typography>

      {/* API */}
      <Typography variant="h2">Integration</Typography>
      <Typography variant="h3">Servers:</Typography>
      <ApiDocServers
        servers={[
          { env: "Production", url: "https://weather-data-eu.atmosoar.io/api" },
          { env: "Test", url: "https://weather-data-test.atmosoar.io/api" },
        ]}
      />
      <Typography variant="h3">Endpoints:</Typography>
      <Typography variant="h4">Metar:</Typography>
      <ApiDocEndpoint
        summary="Get METAR data for the specified airport"
        method="GET"
        url="/metar/station/{icao}"
        parameters={[
          {
            name: "icao",
            type: "string",
            format: null,
            required: true,
            description: "ICAO code of the airport",
          },
        ]}
        responses={[
          {
            code: "200",
            description: "Successful Response",
            contentType: "application/json",
          },
          {
            code: "404",
            description: "Data Not Available",
            contentType: "application/json",
          },
        ]}
        oasUrl="https://github.com/Atmosoar/atms-documentation/blob/master/METAR-TAF-SIGMET-API/OAS3-MetarTafSigmetAPI.yaml"
      />
      <ApiDocEndpoint
        summary="Get METAR data for the specified area"
        method="GET"
        url="/metar/area"
        parameters={[
          {
            name: "north",
            type: "number",
            format: "float",
            required: true,
            description: "North latitude of the area",
          },
          {
            name: "south",
            type: "number",
            format: "float",
            required: true,
            description: "South latitude of the area",
          },
          {
            name: "east",
            type: "number",
            format: "float",
            required: true,
            description: "East longitude of the area",
          },
          {
            name: "west",
            type: "number",
            format: "float",
            required: true,
            description: "West longitude of the area",
          },
        ]}
        responses={[
          {
            code: "200",
            description: "Successful Response",
            contentType: "application/json",
          },
          {
            code: "404",
            description: "Data Not Available",
            contentType: "application/json",
          },
        ]}
        oasUrl="https://github.com/Atmosoar/atms-documentation/blob/master/METAR-TAF-SIGMET-API/OAS3-MetarTafSigmetAPI.yaml"
      />
      <Typography variant="h4">TAF:</Typography>
      <ApiDocEndpoint
        summary="Get TAF data for the specified airport"
        method="GET"
        url="/taf/station/{icao}"
        parameters={[
          {
            name: "icao",
            type: "string",
            format: null,
            required: true,
            description: "ICAO code of the airport",
          },
        ]}
        responses={[
          {
            code: "200",
            description: "Successful Response",
            contentType: "application/json",
          },
          {
            code: "400",
            description: "Bad Request",
            contentType: "application/json",
          },
          {
            code: "404",
            description: "Data Not Available",
            contentType: "application/json",
          },
          {
            code: "500",
            description: "Internal Server Error",
            contentType: "application/json",
          },
        ]}
        oasUrl="https://github.com/Atmosoar/atms-documentation/blob/master/METAR-TAF-SIGMET-API/OAS3-MetarTafSigmetAPI.yaml"
      />
      <ApiDocEndpoint
        summary="Get TAF data for the specified area"
        method="GET"
        url="/taf/area"
        parameters={[
          {
            name: "north",
            type: "number",
            format: "float",
            required: true,
            description: "North latitude of the area",
          },
          {
            name: "south",
            type: "number",
            format: "float",
            required: true,
            description: "South latitude of the area",
          },
          {
            name: "east",
            type: "number",
            format: "float",
            required: true,
            description: "East longitude of the area",
          },
          {
            name: "west",
            type: "number",
            format: "float",
            required: true,
            description: "West longitude of the area",
          },
        ]}
        responses={[
          {
            code: "200",
            description: "Successful Response",
            contentType: "application/json",
          },
          {
            code: "404",
            description: "Data Not Available",
            contentType: "application/json",
          },
        ]}
        oasUrl="https://github.com/Atmosoar/atms-documentation/blob/master/METAR-TAF-SIGMET-API/OAS3-MetarTafSigmetAPI.yaml"
      />
      <Typography variant="h4">Metar + TAF:</Typography>
      <ApiDocEndpoint
        summary="Get METAR + TAF data for the specified area"
        method="GET"
        url="/metar-taf/area"
        parameters={[
          {
            name: "north",
            type: "number",
            format: "float",
            required: true,
            description: "North latitude of the area",
          },
          {
            name: "south",
            type: "number",
            format: "float",
            required: true,
            description: "South latitude of the area",
          },
          {
            name: "east",
            type: "number",
            format: "float",
            required: true,
            description: "East longitude of the area",
          },
          {
            name: "west",
            type: "number",
            format: "float",
            required: true,
            description: "West longitude of the area",
          },
        ]}
        responses={[
          {
            code: "200",
            description: "Successful Response",
            contentType: "application/json",
          },
          {
            code: "400",
            description: "Bad Request",
            contentType: "application/json",
          },
          {
            code: "404",
            description: "Data Not Available",
            contentType: "application/json",
          },
          {
            code: "500",
            description: "Internal Server Error",
            contentType: "application/json",
          },
        ]}
        oasUrl="https://github.com/Atmosoar/atms-documentation/blob/master/METAR-TAF-SIGMET-API/OAS3-MetarTafSigmetAPI.yaml"
      />

      {/* SIGMET Endpoints */}
      <Typography variant="h4">SIGMET:</Typography>

      <ApiDocEndpoint
        summary="Get SIGMET data for the specified point"
        method="GET"
        url="/sigmet/point"
        parameters={[
          {
            name: "lat",
            type: "number",
            format: "float",
            required: true,
            description: "Latitude of the point",
          },
          {
            name: "lon",
            type: "number",
            format: "float",
            required: true,
            description: "Longitude of the point",
          },
        ]}
        responses={[
          {
            code: "200",
            description: "Successful Response",
            contentType: "application/json",
          },
          {
            code: "400",
            description: "Bad Request",
            contentType: "application/json",
          },
          {
            code: "404",
            description: "Data Not Available",
            contentType: "application/json",
          },
        ]}
        oasUrl="https://github.com/Atmosoar/atms-documentation/blob/master/METAR-TAF-SIGMET-API/OAS3-MetarTafSigmetAPI.yaml"
      />

      <ApiDocEndpoint
        summary="Get SIGMET data for the specified area"
        method="GET"
        url="/sigmet/area"
        parameters={[
          {
            name: "lat1",
            type: "number",
            format: "float",
            required: true,
            description: "Latitude of the top-left corner",
          },
          {
            name: "lon1",
            type: "number",
            format: "float",
            required: true,
            description: "Longitude of the top-left corner",
          },
          {
            name: "lat2",
            type: "number",
            format: "float",
            required: true,
            description: "Latitude of the bottom-right corner",
          },
          {
            name: "lon2",
            type: "number",
            format: "float",
            required: true,
            description: "Longitude of the bottom-right corner",
          },
        ]}
        responses={[
          {
            code: "200",
            description: "Successful Response",
            contentType: "application/json",
          },
          {
            code: "400",
            description: "Bad Request",
            contentType: "application/json",
          },
          {
            code: "404",
            description: "Data Not Available",
            contentType: "application/json",
          },
        ]}
        oasUrl="https://github.com/Atmosoar/atms-documentation/blob/master/METAR-TAF-SIGMET-API/OAS3-MetarTafSigmetAPI.yaml"
      />
    </ApiDocPage>
  );
};

export default MetarTafSigmetApi;
