import React from 'react';
import { Typography,  } from '@mui/material';
import ApiDocPage from './ApiDocPage';
import ApiDocEndpoint from './ApiDocEndpoint';
import ApiDocServers from './ApiDocServers';
import QuickTokenEndpoint from './QuickTokenEndpoint';

const WeatherForecastDataApi = () => {
  const authorizationDescription = 
    <span>
      The authentication is required to use this endpoint. <br />
      The requests must be autenticated using an <b>API Key</b> (via <Typography variant='labelcode'>key</Typography> parameter)
      or using a <b>JWT</b> (via <Typography variant='labelcode'>token</Typography> parameter or <Typography variant='labelcode'>Authorization: Bearer</Typography> header).
    </span>
  ;
 
  return (
    <ApiDocPage title="Weather Forecast Data API">

        {/* Description */}
        <Typography variant="h2">
          Description
        </Typography>
        <Typography variant="paragraph">
          This API provides detailed weather data for a specific date and time.
          Call by point (lat,lon) or area/bounding box.
          Models: high resolution, high fidelity WRF, and the main commercial models available in a given
          geography.
          The response includes, on a best available architecture, weather data like temperature, atmospheric pressure, dew point, relative humidity, rainfall and snowfall amount, freezing level, wind speed and direction, wind gust, visibility, and many others.
        </Typography>

        {/* API */}
        <Typography variant="h2">
          Integration
        </Typography>
        <Typography variant="h3">
          Servers:
        </Typography>
        <ApiDocServers servers={[
          {env: 'Production',   url: 'https://weather-data-eu.atmosoar.io/api/forecast'},
          {env: 'Test',         url: 'https://weather-data-test.atmosoar.io/api/forecast'},
        ]} />
        <Typography variant="h3">
          Endpoints:
        </Typography>
        <ApiDocEndpoint 
          summary="Get point forecast"
          method="GET"
          url="/get-point-forecast"
          description={
            "Retrieve weather forecast data for a specific location and datetime."
          }
          authorization={authorizationDescription}
          parameters={[
            {name:'datetime',   type:'string', format:'date-time',  required:true,  description:'Datetime for the forecast in ISO 8601 format'},
            {name:'latitude',   type:'number', format:'float',      required:true,  description:'Latitude of the point'},
            {name:'longitude',  type:'number', format:'float',      required:true,  description:'Longitude of the point'},
          ]}
          responses={[
            {code:'200',  description:'Successful Response',   contentType:'application/json',   schemaName:'PointForecastResponse'},
            {code:'400',  description:'Bad Request ',          contentType:'application/json',   schemaName:'ErrorResponse'},
            {code:'404',  description:'Data Not Available',    contentType:'application/json',   schemaName:'ErrorResponse'},
            {code:'500',  description:'Internal Server Error', contentType:'application/json',   schemaName:'ErrorResponse'},
          ]}
          oasUrl="https://github.com/Atmosoar/atms-documentation/blob/master/Weather%20Forecast%20API/OAS3-WeatherForecastAPI.yaml"
        />
        <ApiDocEndpoint 
          summary="Get path forecast"
          method="POST"
          url="/get-path-forecast"
          description={
            "Retrieve weather forecast data for a specific series of waypoints with datetime."
          }
          authorization={authorizationDescription}
          requestBody={{
            contentType: 'application/json',
            schemaName: 'GetPathForecastRequestBody',
            required: true,
            example: JSON.stringify({
              "path": [
                {
                    "lat": 42.132892,
                    "lng": 12.584234,
                    "datetime": "2024-07-16T07:30:00"
                },{
                    "lat": 42.13367,
                    "lng": 12.58455,
                    "datetime": "2024-07-16T07:35:10"
                }, {
                    "lat": 42.13335,
                    "lng": 12.58565,
                    "datetime": "2024-07-16T07:40:20"
                }
              ]
            }, null, 2),
          }}
          responses={[
            {code:'200',  description:'Successful Response',   contentType:'application/json',   schemaName:'PathForecastResponse'},
            {code:'400',  description:'Bad Request ',          contentType:'application/json',   schemaName:'ErrorResponse'},
            {code:'404',  description:'Data Not Available',    contentType:'application/json',   schemaName:'ErrorResponse'},
            {code:'500',  description:'Internal Server Error', contentType:'application/json',   schemaName:'ErrorResponse'},
          ]}
          oasUrl="https://github.com/Atmosoar/atms-documentation/blob/master/Weather%20Forecast%20API/OAS3-WeatherForecastAPI.yaml"
        />
        <ApiDocEndpoint 
          summary="Get area forecast"
          method="GET"
          url="/get-area-forecast"
          description={
            "Retrieve weather forecast data for a specific location and datetime."
          }
          authorization={authorizationDescription}
          parameters={[
            {name:'datetime', type:'string', format:'date-time',  required:true,  description:'Datetime for the forecast in ISO 8601 format'},
            {name:'north',    type:'number', format:'float',      required:true,  description:'North latitude of the area'},
            {name:'south',    type:'number', format:'float',      required:true,  description:'South latitude of the area'},
            {name:'east',     type:'number', format:'float',      required:true,  description:'East longitude of the area'},
            {name:'west',     type:'number', format:'float',      required:true,  description:'West longitude of the area'},
          ]}
          responses={[
            {code:'200',  description:'Successful Response',   contentType:'application/json',   schemaName:'AreaForecastResponse'},
            {code:'400',  description:'Bad Request ',          contentType:'application/json',   schemaName:'ErrorResponse'},
            {code:'404',  description:'Data Not Available',    contentType:'application/json',   schemaName:'ErrorResponse'},
            {code:'500',  description:'Internal Server Error', contentType:'application/json',   schemaName:'ErrorResponse'},
          ]}
          oasUrl="https://github.com/Atmosoar/atms-documentation/blob/master/Weather%20Forecast%20API/OAS3-WeatherForecastAPI.yaml"
        />

        <QuickTokenEndpoint />
    </ApiDocPage>
  );
};

export default WeatherForecastDataApi;