import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Link } from '@mui/material';
import { borderColor, display, fontWeight } from '@mui/system';

const CodeBox = styled(Box)(({ theme }) => ({
  marginLeft: '2px',
  marginTop: '15px',
  marginBottom: '15px',
  backgroundColor: theme.palette.primary.light,
  '& h5': {
    margin: '0px',
    padding: '5px 10px',
    fontWeight: '600',
  },
  '& pre': {
    margin: '0px',
    padding: '10px',
    backgroundColor: theme.palette.primary.lightTableStripe,
  },
}));

const ApiDocCodeExample = ({ 
  title,
  children
}) => {
  return (
    <CodeBox>
      <Typography variant='h5'>{title}</Typography>
      <Typography variant='sourcecode'>
        <pre>{children}</pre>
      </Typography>
    </CodeBox>
  );
};

export default ApiDocCodeExample;
