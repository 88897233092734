import { useTheme, styled } from "@mui/material/styles";
import { Box, Typography, Container } from "@mui/material";
import DefaultNavigationBar from "./DefaultNavigationBar";

const Root = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "100vh", // Assicura che il contenuto occupi tutta l'altezza della pagina
  display: "flex",
  flexDirection: "column",

  "& h1, & h2, & h3": {
    maxWidth: "800px",
  },
  "& h1": {
    fontSize: "60px",
    lineHeight: "1.2",
    fontWeight: 700,
  },
  "& h2": {
    fontSize: "42px",
    lineHeight: 1.3,
    fontWeight: 500,
  },
  "& h3": {
    fontSize: "20px",
    lineHeight: 1.3,
    fontWeight: 400,
  },
}));

const Content = styled(Box)(({ theme }) => ({
  flex: 1, // Si espande per occupare lo spazio disponibile
  //paddingBottom: theme.spacing(2), // Padding per evitare che il contenuto tocchi il footer
}));

const Footer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(2),
  textAlign: "center",
  marginTop: "auto", // Spinge il footer in basso
}));

const DefaultPageLayout = ({ children }) => {
  const theme = useTheme();

  return (
    <Root theme={theme}>
      <DefaultNavigationBar />
      <Content>{children}</Content>
      <Footer>
        <Container>
          <Typography variant="body2" color="inherit" fontSize={"18px"}>
            © 2024 Atmosoar - Atmospheric Soaring Technologies
          </Typography>
        </Container>
      </Footer>
    </Root>
  );
};

export default DefaultPageLayout;
