import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";

import TitleUpdater from "./components/TitleUpdater";
import Home from "./pages/Home/Home";
import WeatherForecastDataApi from "./pages/ApiDocumentation/WeatherForecastDataApi";
import MetarTafSigmetApi from "./pages/ApiDocumentation/MetarTafSigmetApi";
import MissionSafetyAnalysisApi from "./pages/ApiDocumentation/MissionSafetyAnalysisApi";
import ElevationApi from "./pages/ApiDocumentation/ElevationApi";

const defaultTitle = "Atmosoar";
const titleSuffix = " - Atmosoar";
const titlesMapping = {
  "/": defaultTitle,
  "/apis/metar-taf-data": "Metar & TAF Data API" + titleSuffix,
  "/apis/weather-forecast-data": "Weather Forecast Data" + titleSuffix,
  "apis/mission-safety-analysis":
    "Mission Weather Impact Safety Analysis" + titleSuffix,
  "apis/melevation": "Elevation API" + titleSuffix,
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <TitleUpdater
          titlesMapping={titlesMapping}
          defaultTitle={defaultTitle}
        />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/apis" element={<Home />} />
          <Route
            path="/apis/weather-forecast-data"
            element={<WeatherForecastDataApi />}
          />
          <Route
            path="/apis/metar-taf-sigmet"
            element={<MetarTafSigmetApi />}
          />
          <Route
            path="/apis/mission-safety-analysis"
            element={<MissionSafetyAnalysisApi />}
          />
          <Route path="/apis/elevation" element={<ElevationApi />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
