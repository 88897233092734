import { styled } from "@mui/material/styles";
import { Box, Container, Typography, Button } from "@mui/material";
import NavigationBar from "../../components/DefaultNavigationBar";
import PageLayout from "../../components/DefaultPageLayout";
import { useNavigate } from "react-router-dom"; // Importa useNavigate per la navigazione
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

const Root = styled(Box)(({ theme }) => ({
  "& .page-container": {
    paddingTop: "45px",
    paddingBottom: "45px",
  },
  "& table": {
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: theme.palette.common.white,
    borderCollapse: "collapse",
  },
  "& table > thead > tr > td": {
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: theme.palette.common.white,
    backgroundColor: theme.palette.primary.light,
    padding: "5px 10px",
    fontWeight: 600,
  },
  "& table > tbody > tr > td": {
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: theme.palette.common.white,
    backgroundColor: theme.palette.primary.light,
    padding: "5px 10px",
    fontWeight: 400,
  },
  "& table > tbody > tr:nth-child(odd) > td": {
    backgroundColor: theme.palette.primary.lightTableStripe,
  },
}));

const ApiDocPage = ({ title, children }) => {
  const navigate = useNavigate(); // Hook per la navigazione

  return (
    <Root>
      <PageLayout>
        <Container className="page-container">
          {/* Pulsante "Go Back" */}
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate("/")} // Naviga alla home
            style={{ marginBottom: "20px" }} // Aggiungi un po' di spazio sotto il pulsante
          >
            <ArrowBackRoundedIcon /> Go Back
          </Button>

          {/* Titolo della pagina */}
          <Typography
            variant="h1"
            style={{ maxWidth: "100%", wordWrap: "break-word" }}
          >
            {title}
          </Typography>

          {/* Contenuto della pagina */}
          {children}
        </Container>
      </PageLayout>
    </Root>
  );
};

export default ApiDocPage;
