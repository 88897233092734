import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

function DefaultNavigationBar({ children }) {
  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexGrow: 1,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button href="https://atmosoar.io">
                <img src="/favicon.png" alt="Logo" style={{ height: "50px" }} />
                <Typography
                  variant="h6"
                  sx={{ marginLeft: 2, fontWeight: 400, color: "white" }}
                >
                  ATMOSOAR
                </Typography>
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default DefaultNavigationBar;
