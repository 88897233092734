import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Link } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import TableResponsive from './../../components/TableResponsive';
import ApiDocCodeExample from './ApiDocCodeExample';

const Root = styled(Box)(({ theme }) => ({
  marginTop: '15px',
  marginBottom: '15px',
  '& .endpoint-header': {
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 10px)',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      boxSizing: 'border-box',
    },
  },
  '& .endpoint-header > .endpoint-method': {
    display: 'block',
    boxSizing: 'border-box',
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '5px',
    padding: '5px 10px',
    margin: '0px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      display: 'inline-block',
    },
  },
  '& .endpoint-header > .endpoint-url': {
    display: 'block',
    boxSizing: 'border-box',
    flexGrow: '1',
    border: 'none',
    outline: 'none',
    color: theme.palette.primary.dark,
    backgroundColor: 'transparent',
    padding: '4px 10px',
    margin: '0px',
    fontWeight: 400,
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '4px 5px',
    },
  },
  '& .endpoint-header > .endpoint-summary': {
    display: 'block',
    boxSizing: 'border-box',
    color: theme.palette.primary.dark,
    padding: '5px 10px',
    margin: '0px 20px',
    fontWeight: 600,
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '5px 5px',
      margin: '0px',
      textAlign: 'left',
    },
  },
  '& p.MuiTypography-paragraph': {
    marginBottom: '30px',
  },
}));

const renderPropertiesTable = (properties) => (
  <Typography variant="paragraph">
    <TableResponsive>
      <table>
        <thead>
          <tr>
            <td>Name</td>
            <td>Type</td>
            <td>Format</td>
            <td>Required</td>
            <td>Description</td>
          </tr>
        </thead>
        <tbody>
          {Object.entries(properties).map(([key, value]) => (
            <React.Fragment key={key}>
              <tr>
                <td>{key}</td>
                <td>{value.type}</td>
                <td>{value.format || 'N/A'}</td>
                <td>{value.required ? 'yes' : 'no'}</td>
                <td>{value.description}</td>
              </tr>
              {value.properties && (
                <tr>
                  <td colSpan="5">
                    {renderPropertiesTable(value.properties)}
                  </td>
                </tr>
              )}
              {value.items && value.items.properties && (
                <tr>
                  <td colSpan="5">
                    {renderPropertiesTable(value.items.properties)}
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </TableResponsive>
  </Typography>
);

const ApiDocEndpoint = ({ 
  summary,
  method,
  url,
  description,
  authorization,
  parameters, 
  requestBody,
  responses,
  oasUrl,
  children
}) => {
  return (
    <Root>
      <Accordion>
        <AccordionSummary 
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: 'transparent !important',
          }}
        >
          <Box className="endpoint-header">
            <span className="endpoint-method">
              {method}
            </span>
            <input 
              type="text"
              value={url}
              className="endpoint-url code"
              onClick={event => event.stopPropagation()}
              readOnly
            />
            <span className="endpoint-summary">
              {summary}
            </span>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{marginTop: '-30px'}}>
          {description && 
            <>
              <Typography variant="h4">Description:</Typography>
              <Typography variant="paragraph">
                {description}
              </Typography>
            </>
          }
          {authorization && 
            <>
              <Typography variant="h4">Authorization:</Typography>
              <Typography variant="paragraph">
                {authorization}
              </Typography>
            </>
          }
          {parameters && 
            <>
              <Typography variant="h4">Parameters:</Typography>
              <Typography variant="paragraph">
                <TableResponsive>
                  <table>
                    <thead>
                      <tr>
                        <td>Name</td>
                        <td>Type</td>
                        <td>Format</td>
                        <td>Required</td>
                        <td>Description</td>
                      </tr>
                    </thead>
                    <tbody>
                      {parameters.map((item, index) => (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.type}</td>
                          <td>{item.format}</td>
                          <td>{item.required === true ? 'yes' : 'no'}</td>
                          <td>{item.description}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </TableResponsive>
              </Typography>
            </>
          }
          {requestBody && 
            <>
              <Typography variant="h4">Request Body:</Typography>
              <Typography variant="paragraph">
                <TableResponsive>
                  <table>
                    <thead>
                      <tr>
                        <td>Content Type</td>
                        <td>Schema</td>
                        <td>Required</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{requestBody.contentType}</td>
                        <td>{requestBody.schemaName}</td>
                        <td>{(requestBody.required ?? true) ? 'yes' : 'no'}</td>
                      </tr>
                    </tbody>
                  </table>
                </TableResponsive>
              </Typography>
              {requestBody.example &&
                <ApiDocCodeExample title="Example:">
                  {requestBody.example}
                </ApiDocCodeExample>
              }
              {requestBody.schema && requestBody.schema.properties && renderPropertiesTable(requestBody.schema.properties)}
            </>
          }
          {responses && 
            <>
              <Typography variant="h4">Responses:</Typography>
              <Typography variant="paragraph">
                <TableResponsive>
                  <table>
                    <thead>
                      <tr>
                        <td>Code</td>
                        <td>Description</td>
                        <td>Content Type</td>
                        <td>Schema</td>
                      </tr>
                    </thead>
                    <tbody>
                      {responses.map((item, index) => (
                        <tr key={index}>
                          <td>{item.code}</td>
                          <td>{item.description}</td>
                          <td>{item.contentType}</td>
                          <td>{item.schemaName}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </TableResponsive>
              </Typography>
            </>
          }
          {oasUrl && 
            <>
              <Typography variant="h4">OpenAPI Specification:</Typography>
              <Link href={oasUrl} target="_blank" sx={{display:'flex', alignItems:'center'}}>
                <IntegrationInstructionsIcon sx={{marginRight:'5px'}} />Visualize the OpenAPI Specification for this endpoint
              </Link>
            </>
          }
          {children}
        </AccordionDetails>
      </Accordion>
    </Root>
  );
};

export default ApiDocEndpoint;
