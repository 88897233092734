import { createTheme } from '@mui/material/styles';
import { borderRadius, display, lineHeight, padding } from '@mui/system';

// Palette Calculator: https://www.color-hex.com/color/00b8e0

// Create Theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#00b8e0',
      light: '#e5f7fb',
      lightTableStripe: '#f6fcfe',
      dark: '#006e86',
      darkTableStripe: '#007d99',
      contrastText: '#e5f7fb',
    },
    success: {
      main: '#3bbf7e',
    },
    error: {
      main: '#f67775',
    }
  },
  typography: {
    fontFamily: '"Lato", sans-serif',
  },
});

// Set Additional Styles
theme.typography.h1 = {
  color: theme.palette.primary.dark,
  fontSize: '40px',
  fontWeight: '600',
  marginTop: '0px',
  marginBottom: '45px',
};
theme.typography.h2 = {
  color: theme.palette.primary.dark,
  fontSize: '30px',
  fontWeight: '400',
  marginTop: '15px',
  marginBottom: '15px',
};
theme.typography.h3 = {
  color: theme.palette.primary.dark,
  fontSize: '22px',
  fontWeight: '400',
  marginTop: '30px',
  marginBottom: '15px',
};
theme.typography.h4 = {
  color: theme.palette.primary.dark,
  fontSize: '16px',
  fontWeight: '600',
  marginTop: '30px',
  marginBottom: '15px',
};
theme.typography.h5 = {
  color: theme.palette.primary.dark,
  fontSize: '16px',
  fontWeight: '400',
  marginTop: '15px',
  marginBottom: '15px',
};
theme.typography.paragraph = {
  display: 'block',
  color: theme.palette.primary.dark,
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: 1.4,
  marginTop: '15px',
  marginBottom: '45px',
};
theme.typography.labelcode = {
  color: theme.palette.primary.dark,
  backgroundColor: theme.palette.primary.light,
  fontFamily: '"Source Code Pro", monospace',
  display: 'inline',
  padding: '2px 7px',
  borderRadius: '5px',
  fontSize: '14px',
};
theme.typography.sourcecode = {
  color: theme.palette.primary.dark,
  fontFamily: '"Source Code Pro", monospace',
  fontSize: '12px',
};
theme.typography.body1 = {
  color: theme.palette.primary.dark,
};

// Export Theme
export default theme;