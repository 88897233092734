import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography,  } from '@mui/material';
import ApiDocEndpoint from './ApiDocEndpoint';
import ApiDocServers from './ApiDocServers';

const QuickTokenEndpointSection = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  marginTop: '45px',
  padding: '5px 15px',
  borderRadius: '5px',
}));

const QuickTokenEndpoint = ({ servers}) => {
  return (
    <QuickTokenEndpointSection>
      <Typography variant="h4" sx={{ marginTop: '15px', fontSize: '18px' }}>
        Authorization for faster sequential requests:
      </Typography>
      <Typography variant="paragraph"sx={{ marginBottom: '20px' }}>
        If you need to perform multiple calls during a short period of time, we recomend for you to use a <b>Quick Token</b> to authorize your requests. <br />
        A <b>Quick Token</b> is a <b>JWT</b> with a short time validity that you can request using your <b>API Key</b>. <br />
        After obtaining the <b>Quick Token</b> from the endpoint below, you can use it to authorize your requests in place of the <b>API Key</b> for a limited amount of time.
      </Typography>
      <ApiDocServers servers={[
        {env: 'Production',   url: 'https://weather-data-eu.atmosoar.io/api/auth'},
        {env: 'Test',         url: 'https://weather-data-test.atmosoar.io/api/auth'},
      ]} sx={{ marginTop: '20px', marginBottom: '30px' }} />
      <ApiDocEndpoint 
        summary="Get quick token"
        method="GET"
        url="/get-quick-token"
        description={
          "Generate a quick token from your API Key."
        }
        parameters={[
          {name:'key', type:'string', required:true, description:'Your API Key'}
        ]}
        responses={[
          {code:'200', description:'Successful Response',   contentType:'application/json'},
          {code:'400', description:'Bad Request',           contentType:'application/json'},
          {code:'401', description:'Unauthorized',          contentType:'application/json'},
          {code:'500', description:'Internal Server Error', contentType:'application/json'},
        ]}
        oasUrl="https://github.com/Atmosoar/atms-documentation/blob/master/Quick%20Token%20API/OAS3-QuickTokenAPI-WeatherData.yaml"
      />
    </QuickTokenEndpointSection>
  );
};

export default QuickTokenEndpoint;