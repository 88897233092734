import React from "react";
import { Typography } from "@mui/material";
import ApiDocPage from "./ApiDocPage";
import ApiDocEndpoint from "./ApiDocEndpoint";
import ApiDocServers from "./ApiDocServers";
import QuickTokenEndpoint from "./QuickTokenEndpoint";

const ElevationApi = () => {
  return (
    <ApiDocPage title="Elevation API">
      {/* Description */}
      <Typography variant="h2">Description</Typography>
      <Typography variant="paragraph">
        This API allows the user to retrieve the elevation data for a location,
        given the longitude and the latitude. Also, it’s possible to call
        multiple locations in a single request. Global dataset: GTOPO30, ~1km
        resolution. High resolution digital terrain models available for Italy,
        UK, France, Germany; other countries on request.
      </Typography>

      {/* API */}
      <Typography variant="h2">Integration</Typography>
      <Typography variant="h3">Servers:</Typography>
      <ApiDocServers
        servers={[
          {
            env: "Production",
            url: "https://elevation-data-eu.atmosoar.io/api/elevation",
          },
        ]}
      />
      <Typography variant="h3">Endpoint:</Typography>

      <ApiDocEndpoint
        summary="Get elevation for the specified coordinate"
        method="GET"
        url="/point"
        parameters={[
          {
            name: "lat",
            type: "number",
            format: "float",
            required: true,
            description: "Latitude of the point",
          },
          {
            name: "lng",
            type: "number",
            format: "float",
            required: true,
            description: "Longitude of the point",
          },
        ]}
        responses={[
          {
            code: "200",
            description: "Successful Response",
            contentType: "application/json",
          },
          {
            code: "400",
            description: "Bad Request - Invalid parameters",
            contentType: "application/json",
          },
          {
            code: "404",
            description: "Not Found - Data Not Available",
            contentType: "application/json",
          },
          {
            code: "500",
            description: "Internal Server Error",
            contentType: "application/json",
          },
        ]}
        oasUrl="https://github.com/Atmosoar/atms-documentation/blob/master/Elevation-API/OAS3-ElevationAPI.yaml"
      />
      <ApiDocEndpoint
        summary="Get elevations for multiple coordinates"
        method="POST"
        url="/cluster"
        parameters={[
          {
            name: "locations",
            type: "array",
            format: "json",
            required: true,
            description: "Array of locations with latitude and longitude",
          },
        ]}
        requestBody={{
          contentType: "application/json",
          example: JSON.stringify(
            {
              locations: [
                { lat: 48.8566, lng: 2.5778 },
                { lat: 48.6758, lng: 2.5678 },
                { lat: 48.3983, lng: 2.9913 },
                { lat: 48.4647, lng: 2.4799 },
              ],
            },
            null,
            2
          ),
          schema: {
            type: "object",
            properties: {
              locations: {
                type: "array",
                items: {
                  type: "object",
                  properties: {
                    lat: {
                      type: "number",
                      format: "float",
                      description: "Latitude of the point",
                    },
                    lng: {
                      type: "number",
                      format: "float",
                      description: "Longitude of the point",
                    },
                  },
                  required: ["lat", "lng"],
                },
              },
            },
          },
        }}
        responses={[
          {
            code: "200",
            description: "Successful Response",
            contentType: "application/json",
          },
          {
            code: "400",
            description: "Bad Request - Invalid parameters",
            contentType: "application/json",
          },
          {
            code: "404",
            description: "Not Found - Data Not Available",
            contentType: "application/json",
          },
          {
            code: "500",
            description: "Internal Server Error",
            contentType: "application/json",
          },
        ]}
        oasUrl="https://github.com/Atmosoar/atms-documentation/blob/master/Elevation-API/OAS3-ElevationAPI.yaml"
      />
    </ApiDocPage>
  );
};

export default ElevationApi;
