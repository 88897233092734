import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const TitleUpdater = ({ defaultTitle, titlesMapping,  }) => {
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    const title = titlesMapping[currentPath] ?? defaultTitle;

    document.title = title;
  }, [location, titlesMapping, defaultTitle]);

  return null; // This component does not render anything
};

export default TitleUpdater;
